import React from 'react'
import { Button, Modal as ChakraModal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react';
import { Modal, VerticalStack } from '@shopify/polaris';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';
import '../../../Style/customWix.css';

const ModalView = (props) => {
    const {hideClose, active, activator, onSecondaryAction, onPrimaryAction, src, onIFrameLoad, content, title, primaryButtonLabel, secondaryButtonLabel, primaryButtonClass, secondaryButtonClass, isPrimaryButtonDanger = false, onClose } = props;
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <ChakraModal onClose={()=>{onClose ? onClose () : onSecondaryAction()}} isOpen={active} isCentered>
                    <ModalOverlay />
                    <ModalContent className='wix-modal'>                    
                    <ModalHeader>{title}</ModalHeader>
                      {!hideClose && <ModalCloseButton/>}
                        <ModalBody>
                            {content && content}
                            {src && <iframe title='My Modal Iframe' width='100%' height='100%' src={src} frameBorder='0' onLoad={onIFrameLoad} />}
                        </ModalBody>
                        <ModalFooter>
                            <Stack spacing={3} direction='row' align='center'>
                                <Button variant='secondary' className={secondaryButtonClass} onClick={onSecondaryAction}>{secondaryButtonLabel}</Button>
                                {onPrimaryAction && <Button colorScheme='teal' className={ isPrimaryButtonDanger ? 'primary-button-dander' : primaryButtonClass } variant='solid' onClick={onPrimaryAction}>{primaryButtonLabel}</Button>}
                            </Stack>
                        </ModalFooter>
                    </ModalContent>
                </ChakraModal>
                :
                <Modal
                    activator={activator}
                    open={active}                    
                    onClose={()=>{onClose ? onClose () : onSecondaryAction()}}
                    title={title}
                    src={src}
                    onIFrameLoad={onIFrameLoad}
                    primaryAction={ onPrimaryAction && {
                        destructive: isPrimaryButtonDanger,
                        content: primaryButtonLabel,
                        onAction: onPrimaryAction,
                    }}
                    secondaryActions={[
                        {
                            content: secondaryButtonLabel,
                            onAction: onSecondaryAction,
                        },
                    ]}
                >
                    <Modal.Section>
                        <VerticalStack>
                            <p>
                                {content && content}
                            </p>
                        </VerticalStack>
                    </Modal.Section>
                </Modal>
            }
        </>
    )
}

export default ModalView