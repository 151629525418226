import React, { useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';
import Image from './Components/ImageSelector';
import FilterContext from './Components/FilterContext';
import { PageData } from '../../../ContextAPI/Context';
import { getSelectedWidget, getTargeted, getTargetedValue } from '../../../Utils/Common';
import ProductUpsell from './Components/ProductUpsell';
import CustomizeSection from './Components/CustomizeSection';
import { WidgetType, ButtonEvent, UpsellBlocks, PlatformType } from '../../../Constant';
import { CDN_BASE_URL, PLATFORM } from '../../../config';
import { Header, Skeleton, Icon } from '../../../Shared/Components';
import { IconSource } from '../../../Shared/Components/Icon';
import RenderRightComponent from './Components/Shared/RenderRightComponent';
import SuccessErrorBanner from './Components/SuccessErrorBanner';
import RightBarSectionLinks from './Components/RightBarSectionLinks';

const RightSideBar = (props) => {
  const { configSetting, unifiedEditor, updateData, setBannerStatus, isRequiredDataFilled, checkRemovable, previewSelectedSections, updateRightBarLinks} = useContext(PageData);
  const { componentType, count, minCount, target, id, widgetId, parentWidgetId, blockType } = props.renderComponent;
  const { loading, setLoading, backButton } = props;
  const [componentTitle, setComponentTitle] = useState('');

  const latestSetting = useMemo(() => {
    return cloneDeep(configSetting[configSetting.length - 1]);
  }, [configSetting]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }, [componentType, target, id, widgetId, parentWidgetId, setLoading, loading]);

  useEffect(() => {
    setBannerStatus({});
  }, [componentType, id, setBannerStatus]);

  useEffect(() => {
    backButton(ButtonEvent.Back);
  }, [backButton]);

  useEffect(() => {
    const targetedWidget = getTargetedValue(latestSetting, target, widgetId, parentWidgetId, blockType, id);
    const selectedWidget = getSelectedWidget(targetedWidget, unifiedEditor.widgets);
    setComponentTitle(selectedWidget?.sectionName ? selectedWidget?.sectionName : selectedWidget?.blockName);
  }, [componentType]);

  const shouldRemoveWidget = useMemo(() => {
    if (!id) {
      return checkRemovable(minCount, count);
    }
  }, [parentWidgetId, widgetId, minCount, count, id, target]);

  const switchData = () => {
    if (componentType === WidgetType.Upsell) {
      return (
        <FilterContext>
          <ProductUpsell type={props.renderComponent} />
        </FilterContext>
      );
    } else if (componentType === UpsellBlocks.Image) {
      return <Image type={props.renderComponent} />;
    } else if (componentType === UpsellBlocks.SuccessErrorBanner) {
      return <SuccessErrorBanner type={props.renderComponent} />;
    } else if (componentType) {
      return <RenderRightComponent type={props.renderComponent} />;
    } else {  
      return <CustomizeSection />;
    }
  };

  const removeElement = (key, value, newTarget = target, isRemoved, shouldBack = true) => {
    let latestSetting = cloneDeep(configSetting[configSetting.length - 1]);

    const previewData = getTargeted(latestSetting, newTarget, 1, widgetId, parentWidgetId, blockType, id);
    const index = previewData.findIndex((item) => item[key] == value);

    if (index >= 0) {
      if (widgetId && parentWidgetId && !id && previewData.length === 1 && !isRemoved) {
        newTarget = newTarget.split('.');
        newTarget.splice(newTarget.length - 2, 2);
        removeElement(key, parentWidgetId, newTarget.join('.'), true);
      } else {
        let removedData = previewData.splice(index, 1);
        if(removedData && removedData?.[0]?.type ===  WidgetType.Upsell && removedData?.[0]?.parentType ===  WidgetType.Bundle) {
            const parentData = getTargeted(latestSetting, newTarget, 2, widgetId, parentWidgetId, blockType, id, 2);
            const currentLength = previewData.filter(data=> data.type ===  WidgetType.Upsell)?.length ?? 1;
            parentData.noOfProducts = currentLength <  parentData.noOfProducts ? currentLength : parentData.noOfProducts
        }

        if(removedData && removedData?.[0]?.parentType ===  WidgetType.Bundle) {
          const parentData = getTargeted(latestSetting, newTarget, 2, widgetId, parentWidgetId, blockType, id, 2);
          parentData.widgets.forEach((data)=> {
            if(data.displayOrder >= removedData?.[0]?.displayOrder) {
              data.displayOrder = data.displayOrder - 1;
            }
          })
          parentData.blocks.forEach((data)=> {
            if(data.displayOrder >= removedData?.[0]?.displayOrder) {
              data.displayOrder = data.displayOrder - 1;
            }
          })
        }

        // used to dynamically update the right bar links
        updateRightBarLinks(latestSetting,componentType,parentWidgetId,widgetId);
        updateData(latestSetting);  
        if(shouldBack) {
          props.backButton(ButtonEvent.Back);
        }
      }
    }
  };

  useEffect(() => {
    if(previewSelectedSections.id === parentWidgetId) {
      const selectedWidget = getTargetedValue(latestSetting, target , '', parentWidgetId);
      const products = selectedWidget?.widgets && selectedWidget.widgets.filter(widget => widget.type === WidgetType.Upsell);
      const upsellCount = products?.length;
      if(upsellCount && upsellCount > selectedWidget?.noOfProducts ) {
       removeElement('id', products[upsellCount - 1].id, `${target}.widgets.[id:{WIDGET_ID}]`, '', false);
      }
    }
  },[latestSetting, parentWidgetId, previewSelectedSections]);

  const getRemoveButton = (key, label, value) => {
    return (
      <div className={`${PLATFORM === PlatformType.Wix ? 'wix' : 'shopify'} bottom_container`}>
        <div className='remove_button fontStyle' onClick={() => removeElement(key, value)}>
          <Icon source={IconSource.Delete} iconAlt={'remove'}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.16667 16.6666C4.16667 17.1087 4.34226 17.5326 4.65482 17.8451C4.96738 18.1577 5.39131 18.3333 5.83333 18.3333H14.1667C14.6087 18.3333 15.0326 18.1577 15.3452 17.8451C15.6577 17.5326 15.8333 17.1087 15.8333 16.6666V6.66663H17.5V4.99996H14.1667V3.33329C14.1667 2.89127 13.9911 2.46734 13.6785 2.15478C13.3659 1.84222 12.942 1.66663 12.5 1.66663H7.5C7.05797 1.66663 6.63405 1.84222 6.32149 2.15478C6.00893 2.46734 5.83333 2.89127 5.83333 3.33329V4.99996H2.5V6.66663H4.16667V16.6666ZM7.5 3.33329H12.5V4.99996H7.5V3.33329ZM6.66667 6.66663H14.1667V16.6666H5.83333V6.66663H6.66667Z" fill="#C53030" />
              <path d="M7.5 8.33337H9.16667V15H7.5V8.33337ZM10.8333 8.33337H12.5V15H10.8333V8.33337Z" fill="#C53030" />
            </svg>
          </Icon>
          <span>{t('remove')} {t(label)?.toLowerCase()}</span>
        </div>
      </div>
    )
  }

  return (
    <div className='right_container'>
      <div className='right-section overflow_vertical_scroll'>
        <div className='right-section-title'>
          <div
            className='back-arrow'
            onClick={() => {
              if (isRequiredDataFilled()) {
                props.backButton(ButtonEvent.Back);
              }
            }}
          >
            <img src={CDN_BASE_URL + `assets/chevron-left.svg`} alt={`chevron left icon`} />
          </div>
          <Header label={componentTitle} />
        </div>

        <div>{loading ? <Skeleton /> : switchData()}</div>
        
        <RightBarSectionLinks rightBarComponentType={componentType} widgetId={widgetId} />
      </div>

      <div className='bottom_remove_buttons'>
        { 
          // Remove Block form the product upsell
        }
        {count !== minCount && id && componentType && (
          getRemoveButton('id', 'block', id)
        )}
        { 
          // Remove all widgets except the last one, which should not be deleted.
        }
        {shouldRemoveWidget && componentType &&
          getRemoveButton('id', 'widget', widgetId ? widgetId : parentWidgetId)
        }
      </div>
    </div>
  );
}

export default RightSideBar;
