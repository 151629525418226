import { useContext, useEffect, useState } from 'react';
import { PageData } from '../../ContextAPI/Context';
import { cloneDeep } from 'lodash';
import { getTargetedValue } from '../../Utils/Common';
import { getNumberOfWidget } from '../Layout/LeftSideBar/Utils/getNumberOfWidget';
import { PLATFORM } from '../../config';
import { PlatformType } from '../../Constant';
import Text from '../../assets/Text';
import ProductUpsell from '../../assets/ProductUpsell';
import ProductRecommendation from '../../assets/ProductRecommendation';
import Bundle from '../../assets/Bundle';
import Banner from '../../assets/Banner';
import Timer from '../../assets/Timer';
import { Box, Button, Center, Checkbox } from '@chakra-ui/react';
import { Icons, Typography } from '@reconvert/react-ui-component';

const slotLits = (addSectionOptions) => {
  return addSectionOptions
    .map((item) => {
      let emptyPreviewIcon, title;

      switch (item.label) {
        case 'bundle':
          title = 'Bundle';
          emptyPreviewIcon = <Bundle />;
          break;
        case 'product_recommendations':
          title = 'Product recommendations';
          emptyPreviewIcon = <ProductRecommendation />;
          break;
        case 'product_upsell':
          title = 'Product upsell';
          emptyPreviewIcon = <ProductUpsell />;
          break;
        case 'text':
          title = 'Text';
          emptyPreviewIcon = <Text />;
          break;
        case 'banner':
          title = 'Banner';
          emptyPreviewIcon = <Banner />;
          break;
        case 'timer':
          title = 'Timer';
          emptyPreviewIcon = <Timer />;
          break;
        case 'product':
          title = 'Product';
          emptyPreviewIcon = <ProductUpsell />;
          break;
        default:
          return null;
      }

      return { ...item, emptyPreviewIcon, title };
    })
    .filter((item) => item !== null);
};

const EmptyPreview = () => {
  const {
    unifiedEditor,
    configSetting,
    setAddWidgetByTarget,
    isRequiredDataFilled,
    setIsShowWidgetLimitModal,
  } = useContext(PageData);
  const { addSectionOptions } = unifiedEditor;

  const [slots] = useState(slotLits(addSectionOptions));
  const [selectedSlots, setSelectedSlots] = useState([]);

  const handleAddSection = (v) => {
    if (isRequiredDataFilled()) {
      const data = unifiedEditor.addSectionOptions;
      const index = data.findIndex((item) => item.value === v);
      const latestData = cloneDeep(configSetting[configSetting.length - 1]);
      const getAddedSection = getTargetedValue(
        unifiedEditor,
        data[index].value
      );
      const newWidgetCount = getAddedSection?.widgets?.length || 0;
      const numberOfWidget = getNumberOfWidget(latestData?.widgets);
      if (
        PLATFORM === PlatformType.Wix &&
        numberOfWidget + newWidgetCount >= 10
      ) {
        setIsShowWidgetLimitModal(true);
      } else if (index >= 0) {
        let selectedOption = data[index];
        setAddWidgetByTarget(selectedOption.value);
      }
    }
  };

  const handleAddClick = async () => {
    for (let key of selectedSlots) {
      await handleAddSection(key);
    }
  };

  return (
    <Center mt="24px">
      <style>
        {`
          p{
          margin:0;
          }
        `}
      </style>
      <Box bg="#fff" p={'24px'} maxW={'642px'}>
        <Box>
          <Typography mb={0} variant="h3-r-medium">
            Select widgets to add
          </Typography>
        </Box>
        <Box my={'24px'}>
          <Center>
            <Box
              sx={{ justifyContent: 'center' }}
              display={'flex'}
              mt={6}
              flexWrap={'wrap'}
              justifyContent={'center'}
              gap={'24px'}
              mx={'auto'}
            >
              {slots.map(({ title, emptyPreviewIcon, value }) => (
                <SlotTemplateButton
                  checkbox
                  key={value}
                  isSelected={selectedSlots.some((item) => item === value)}
                  icon={emptyPreviewIcon}
                  onClick={() => {
                    if (selectedSlots.some((item) => item === value)) {
                      setSelectedSlots(
                        selectedSlots.filter((item) => item !== value)
                      );
                    } else {
                      setSelectedSlots((prev) => [...prev, value]);
                    }
                  }}
                  title={title}
                />
              ))}
            </Box>
          </Center>
        </Box>
        <Center mt="24px">
          <Button
            w="100%"
            sx={{
              bg: 'linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), var(--Neutral-900, #121417)',
              border: '1px solid var(--Neutral-700, #363C45)',
              padding: '8px 16px',
              color: 'white',
              boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: '8px',
              fontWeight: '500',
              _hover: {
                bg: '#363C45',
                _disabled: {
                  bg: '#D9D9D9',
                  color: '#8F8F8F',
                },
              },
              _focusVisible: {
                boxShadow: 'outline',
              },
              _disabled: {
                bg: '#D9D9D9',
                color: '#8F8F8F',
                cursor: 'not-allowed',
                boxShadow: 'none',
                border: 'none',
                opacity: 1,
              },
              _loading: {
                color: 'white',
              },
              _active:{
                boxShadow: "0px 4px 4px 0px #000 inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.25)",
              }
            }}
            onClick={handleAddClick}
            isDisabled={selectedSlots?.length === 0}
          >
            <Center gap="8px">
              <Icons.PlusCircle />
              Add
            </Center>
          </Button>
        </Center>
      </Box>
    </Center>
  );
};

const SlotTemplateButton = ({ icon, title, checkbox, isSelected, onClick }) => {
  const [isCardSelected, setIsCardSelected] = useState(isSelected || false);

  useEffect(() => {
    setIsCardSelected(isSelected || false);
  }, [isSelected]);

  const handleOnClick = () => {
    setIsCardSelected(!isCardSelected);
    onClick && onClick(!isCardSelected);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: isCardSelected
          ? '1px solid var(--Neutral-800, #1F2329)'
          : '1px solid var(--Neutral-400, #DADEE5)',
        maxW: 180,
        minWidth: 180,
        borderRadius: '8px',
        ...(isCardSelected
          ? {
              '&:hover': {
                cursor: 'pointer',
              },
            }
          : {
              '&:hover': {
                border: '1px solid var(--Secondary-900, #6A7381)',
                cursor: 'pointer',
                '.card-button-text': {
                  background: '#6A7381',
                  p: {
                    color: '#fff',
                  },
                },
              },
            }),
      }}
      onClick={(e) => {
        e.preventDefault();
        handleOnClick();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          borderBottom: '1px solid #DADEE5',
        }}
        p={'16px'}
      >
        {checkbox && (
          <Box sx={{ alignSelf: 'flex-end' }}>
            <Checkbox
              sx={{
                width: '16px',
                height: '16px',
                border: '1px solid',
                borderColor: '#363C45',
                borderRadius: '2px',
                color: '#1F2329 !important',
              }}
              _checked={{
                background: '#1F2329',
                borderColor: '#1F2329',
                color: 'white',
                _hover: {
                  borderColor: '#1F2329',
                  bg: '#1F2329',
                },
              }}
              icon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="checked icon">
                    <g id="Icon">
                      <path
                        id="Path"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.6515 5.1415C11.8495 4.94901 12.166 4.95347 12.3585 5.15146C12.551 5.34945 12.5465 5.666 12.3485 5.8585L7.20568 10.8585C7.01162 11.0472 6.70266 11.0472 6.5086 10.8585L3.65146 8.08072C3.45347 7.88823 3.44901 7.57168 3.6415 7.37368C3.834 7.17569 4.15055 7.17123 4.34854 7.36373L6.85714 9.80265L11.6515 5.1415Z"
                        fill="white"
                      />
                    </g>
                  </g>
                </svg>
              }
              isChecked={isCardSelected}
              onChange={(e) => {
                e.preventDefault();
                handleOnClick();
              }}
            />
          </Box>
        )}
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flex={1}
        >
          {icon}
        </Box>
      </Box>
      <Box
        className="card-button-text"
        sx={{
          background: isCardSelected ? '#1F2329' : '#F7F9FC',
          padding: '17px',
          borderRadius: '0px 0px 8px 8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxH: '50px',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="xsmall"
          fontWeight={500}
          color={isCardSelected ? '#fff' : '#121417'}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyPreview;
