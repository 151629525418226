import React, { useCallback, useEffect, useState,useContext } from 'react';
import { TextField } from '@shopify/polaris';
import { Input, InputGroup, InputLeftElement,FormErrorMessage,FormControl} from '@chakra-ui/react';
import { t } from 'i18next';
import { useDebounceValue } from 'usehooks-ts'

import styles from './TextInput.module.css';
import Label from '../Label';
import { PLATFORM } from '../../../config';
import { PlatformType, WidgetType} from '../../../Constant';
import { PageData } from '../../../ContextAPI/Context';

const TextInput = (props) => {    
    const { tooltipText, showTooltip, label, value, onChange, placeholder, multiline, autoComplete, prefix ,currentWidgets={},maxLimitWaringMessage,maxLength} = props;  
    const {currentId,selectedWidgetType,latestConfigWidgets,productUpsellWarningMessages} = currentWidgets
    const {requiredData,setRequiredData } = useContext(PageData);
    const [text, setText] = useState(value);
    const [warningMessage, setWarningMessage] = useState('');    
    const [debouncedValue,setDebouncedValue] = useDebounceValue(text, 500)
    
    useEffect(() => {
        setText(value);        
    }, [value]);

    useEffect(()=>{ 
        if(maxLength){   
            if (text.length > maxLength){                                
                setWarningMessageFunc(true,maxLimitWaringMessage)
            }
            else{
                clearWarningMessage()
            }   
        }    
    },[maxLength,text])

    useEffect(() => {
        if([WidgetType.MultiProductUpsell, WidgetType.Recommendations, WidgetType.Bundle].includes(selectedWidgetType)){
            const validateName = () => {  
                if(debouncedValue) {                
                    const isDuplicate = latestConfigWidgets.some((item) =>{ 
                        if(item.type === selectedWidgetType && item.name === debouncedValue && item.id !== currentId)return true                    
                        });                               
                    if (isDuplicate){    
                        setWarningMessageFunc(true,productUpsellWarningMessages.unique_name)
                        setWarningMessage(productUpsellWarningMessages.unique_name);
                    }
                    else{                                                                          
                        clearWarningMessage()                
                    }                   
                } else if (productUpsellWarningMessages.required) {
                    setWarningMessageFunc(true, productUpsellWarningMessages.required)
                    setWarningMessage(productUpsellWarningMessages.required);
                } else {                                                                          
                    clearWarningMessage()                
                }
              };
              validateName()  
              return clearWarningMessage;       
        }      
      }, [debouncedValue,selectedWidgetType])

    const handleChange = useCallback((e) => {        
        setText(e);
        onChange(e);
        [WidgetType.MultiProductUpsell, WidgetType.Recommendations, WidgetType.Bundle].includes(selectedWidgetType) && setDebouncedValue(e)
    }, [onChange]);    

    const clearWarningMessage = useCallback(() => {     
        setWarningMessageFunc(false,'')
        setWarningMessage(""); 
    }, []);    

    const setWarningMessageFunc = useCallback((status,errorMessage) => {     
        let requiredDataObject = { ...requiredData, showToast:status, showErrorMessage:status,stopChanges: status, toastMessage:errorMessage, isDisable:status}                
        setRequiredData(requiredDataObject);                              
        setWarningMessage(errorMessage); 
    }, []);    
    
    return (
        <div className={`${styles.input_wrap}`}>
            {label && <Label label={t(label)} tooltipText={tooltipText} showTooltip={showTooltip} />}
            {PLATFORM === PlatformType.Wix ?
             <FormControl isInvalid={warningMessage}>
                <InputGroup>
                    {prefix && 
                    <InputLeftElement pointerEvents='none'>
                        {prefix}
                    </InputLeftElement>
                    }
                    <Input
                        className={styles.input_field}
                        value={text}
                        onChange={(e) => handleChange(e?.target?.value)}
                        autoComplete={autoComplete}
                        multiline={multiline ? 'true' : 'false'}
                        placeholder={t(placeholder)}
                        focusBorderColor={warningMessage?"#E53E3E":""}
                    />
                </InputGroup>
                    <FormErrorMessage>{warningMessage}</FormErrorMessage>
                </FormControl>
                :
                <TextField
                    prefix={prefix}
                    multiline={multiline ? true : false}
                    value={text}
                    onChange={handleChange}
                    autoComplete={autoComplete}
                    placeholder={t(placeholder)}
                    error={warningMessage}
                />
            }
        </div>
    );
}

export default TextInput;
