import { cloneDeep } from 'lodash';
import { ConditionalKeys, TargetKeys, WidgetType, ReplaceTargetPlaceHolders, ConditionalOperators, LogicalOperators, TargetConditionTypes, TwoSectionLayout, ThreeSectionLayout, PreviewType, ThreeSectionLayoutSettings, BlockLocation, ThreeSectionLayoutVisible, ProductDisplayTypes, MultiProduct, Layouts, DefaultLayoutForWidget, UpsellBlocks, QueryParams, ViewType } from '../Constant';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
const { v4: uuidv4 } = require('uuid');


/**
 * Get the targeted key and widget data for given target path
 * @param {object[]} widgets - The preview object.
 * @param {string} targetPath - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {number} count - The count to path of the targeted key
 * @returns {object} - The targeted key and targeted widget object
 **/
function getTargetKey(widgets, targetPath, count = 0) {
  try {
    if (!targetPath) {
      return '';
    }

    let targetKey = targetPath?.[count];

    if (targetKey?.includes('[')) {
      targetKey = targetKey.replace(/([^a-z0-9_/:]+)/gi, '');

      const targetKeyToken = targetKey.split(':');
      if (targetKeyToken[0].toString().toLowerCase() === TargetKeys.Setting) {
        targetKey = targetKeyToken[1];
      } else if(targetKeyToken[0].toString().toLowerCase() === TargetKeys.index) {
        targetKey = widgets.findIndex((_, index) =>  index === parseInt(targetKeyToken[1]));
      } else {
        targetKey = widgets.findIndex((widget) => {
          return widget[targetKeyToken[0]].replace(/([^a-z0-9_/:]+)/gi, '').toString().toLowerCase() === targetKeyToken[1].toLowerCase();
        })
      }
    }

    if (count === targetPath?.length - 1) {
      return { targetKey, widget: widgets };
    }

    count++;
    return getTargetKey(widgets[targetKey], targetPath, count);
  } catch (err) {
    console.warn('targeted key err - ', err);
    return '';
  }
}
/**
 * Check given condition is satisfied with target key and widgets array or not
 * @param {object[]} widgets - The preview object.
 * @param {string} targets - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {string} widgetId - The id of the block's widget
 * @param {string} parentWidgetId - The id of the block's parent widget
 * @param {string} type - Type of sections to replace place holder in target
 * @returns {object} - The targeted object
 */

export function getTargetedValue(widgets, targets, widgetId, parentWidgetId, type, id, index) {
  if (!targets || !widgets) {
    return;
  }

  targets = replaceTargetIDs(targets, widgetId, parentWidgetId, type, id, index);
  targets = targets?.split('.');

  const { targetKey, widget } = getTargetKey(widgets, targets);
  if (targetKey === undefined || widget === undefined) {
    return '';
  } else {
    return widget?.[targetKey];
  }
}

/**
 * To fetch targeted widget data
 * @param {object[]} state - The preview object.
 * @param {string} target - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {number} index - The index of the targeted key
 * @param {string} widgetId - The id of the block's widget
 * @param {string} parentWidgetId - The id of the block's parent widget
 * @param {string} type - Type of sections to replace place holder in target
 * @param {number} removeValue - The value to be removed from the targeted key
 * @return {object} - The targeted object
 */
export const getTargeted = (state, target, index, widgetId, parentWidgetId, type, id, removeValue = 1, targetedindex) => {
  target = replaceTargetIDs(target, widgetId, parentWidgetId, type, id, targetedindex);
  target = getTargetString(target, index, removeValue);
  return getTargetedValue(state, target, '', '', '', '', targetedindex);
}

/**
 * To update targeted path key string
 * @param {string} target - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {string} widgetId - The id of the block's widget
 * @param {string} parentWidgetId - The id of the block's parent widget
 * @param {string} type - Type of sections to replace place holder in target
 * @returns {string} - The updated target key string with replaced data
 */
const replaceTargetIDs = (target, widgetId, parentWidgetId, type, id, index) => {
  if (target) {
    target = target.replace(ReplaceTargetPlaceHolders.SettingType, type);
    target = target.replace(ReplaceTargetPlaceHolders.Type, type);
    target = target.replace(ReplaceTargetPlaceHolders.Id, id);
    target = target.replace(ReplaceTargetPlaceHolders.WidgetId, widgetId);
    target = target.replace(ReplaceTargetPlaceHolders.TopWidgetId, parentWidgetId);
    target = target.replace(ReplaceTargetPlaceHolders.Index, index);
  }
  return target;
}

/**
 * Substring target keys from end of target and return it
 */
export const getTargetString = (target, index = 1, i = 1) => {
  target = target?.split('.');
  target?.splice(target.length - index, i);
  return target?.join('.');
}

/**
 *  Update the Object value by the target Value (String) 
    e.g :-  'sections.[sectionType:title].sectionSettings.titleText' 
 * @param {object[]} widgets - The preview object.
 * @param {string} targets - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {string} value - The value to update the targeted key
 * @param {string} widgetId - The id of the block's widget
 * @param {string} parentWidgetId - The id of the block's parent widget
 * @param {string} type - Type of sections to replace place holder in target
 * @returns {object} - The updated value target object
 */
export const updateTargetedValue = (widgets, targets, value, widgetId, parentWidgetId, type, blockId, index, unifiedEditor) => {
  if (!targets || !widgets) {
    return;
  }

  targets = replaceTargetIDs(targets, widgetId, parentWidgetId, type, blockId, index);
  targets = targets.split('.');

  if (!value && typeof value !== 'boolean' && typeof value !== 'number') {
    value = '';
  }

  const { targetKey, widget } = getTargetKey(widgets, targets);

  if (targetKey === "allowSeparateQuantitySelection") {
    if (value) {
      const blocksList = getBlocksFromWidgets(unifiedEditor.defaultSections, true);
      const defaultBlockForQTY = blocksList.find(data => data.type === UpsellBlocks.QuantitySelector);
      widget.widgets?.forEach((data) => {
        const id = Ids()
        if (data.type === WidgetType.Upsell) {
          data.blocks.push({ ...defaultBlockForQTY, id: id })
        }
      })
      let index = widget.blocks.findIndex(data => data.type === UpsellBlocks.QuantitySelector);
      if (index > -1) {
        widget.blocks.splice(index, 1)
      }
    } else {
      const blocksList = getBlocksFromWidgets(unifiedEditor.defaultSections, true);
      const defaultBlockForQTY = blocksList.find(data => data.type === UpsellBlocks.QuantitySelector);
      const id = Ids()
      widget.widgets?.forEach((data) => {
        if (data.type === WidgetType.Upsell) {
          let index = data.blocks.findIndex(data => data.type === UpsellBlocks.QuantitySelector);
          if (index > -1) {
            data.blocks.splice(index, 1)
          }
        }
      })
      widget.blocks.push({ ...defaultBlockForQTY, id: id, displayOrder: Number.MAX_SAFE_INTEGER }) 
    }
  }

  if (targetKey !== undefined || targetKey !== null) {
    widget[targetKey] = value;
    return widgets;
  }
}

/**
 * For generating unique id for each block
 */
export function Ids() {
  return uuidv4();
}

/**
 * @param {*} data - editor addSectionOptions inside sections name if present
 * @param {*} widgets - The widget list object.
 * @returns updated sectionName for each widget
 */
export const updateSchemaProperties = (data, widgets) => {

  const updateSections = [];
  for (let index = 0; index < widgets.length; index++) {
    for (let innerIndex = 0; innerIndex < data.length; innerIndex++) {
      if (widgets[index].type === data[innerIndex].type) {
        let cloneData = cloneDeep(widgets[index]);
        cloneData.sectionName = data[innerIndex].sectionName ?? cloneData.sectionName;
        cloneData.minCount = data[innerIndex].minCount ?? cloneData.minCount;
        updateSections.push(cloneData);
        break;
      }
    }
  }
  return updateSections;
}

/**
 * For fetching left side widgets data
 * @param {object[]} editorSchema - The preview object.
 * @param {object[]} widgets - The widget list object.
 * @param {string} target - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {string} newTarget - The key string of new target path
 * @param {string} key - The key to match
 * @returns {object[]} - Updated widget list object
 */
export const getWidgets = (editorSchema, widgets, target, previewType, widgetType) => {
  const widgetData = [];
  const targetWidgetId = `${target}.${TargetKeys.WidgetId}`
  const key = TargetKeys.Key;
  const editorSchemaClone = structuredClone(editorSchema);
  let parentWidgetType = widgetType;

  if (widgets?.length > 0) {
    for (let index = 0; index < widgets.length; index++) {

      let editorWidget = {};
      if (ThreeSectionLayout.includes(widgets[index].type)) {
        const editorUpsellWidget = editorSchemaClone.find((item) => item.type === WidgetType.Upsell);
        editorWidget = { ...ThreeSectionLayoutSettings[widgets[index].type], blocks: editorUpsellWidget.blocks };
      } else {
        editorWidget = editorSchemaClone.find((item) => item.type === widgets[index].type);
      }
      // MultiProduct is a constant that indicate the array of multi-level widgets. 
      // Preview type is post purchase and layout is two column or three column that means that it is a multi-level widget.
      if (MultiProduct.includes(widgets[index].type) || (widgets[index].type === WidgetType.Upsell && previewType === PreviewType.POST_PURCHASE && [Layouts.TWO_COLUMN, Layouts.THREE_COLUMN].includes(widgets[index].upsellInfo.layout) && widgets[index]?.parentType !== WidgetType.Bundle)) {
        const parentWidgetTarget = TargetKeys.TopWidgetId;
        // If Widget is a multi-level widget, it stores the parent widget type to check the condition of its children blocks.
        if (MultiProduct.includes(widgets[index].type) || (widgets[index].type === WidgetType.Upsell && previewType === PreviewType.POST_PURCHASE)) {
          parentWidgetType = widgets[index].type;
        }

        let childWidgets = getWidgets(editorSchemaClone, widgets[index].widgets, `${target}.${parentWidgetTarget}.${TargetKeys.Widgets}`, previewType, parentWidgetType);
        let childBlocks = (widgets[index]?.blocks && widgets[index]?.blocks?.length) ? getWidgets(editorSchemaClone, widgets[index].blocks, `${target}.${parentWidgetTarget}.${TargetKeys.Blocks}`, previewType, parentWidgetType) : [];
        if (childWidgets && editorWidget?.addSectionOptions) {
          childWidgets = updateSchemaProperties(editorWidget?.addSectionOptions, childWidgets);
        }
        // Added child blocks for parent widget.
        if(childBlocks && childBlocks?.length && editorWidget?.addSectionOptions) {
          childBlocks = getBlocks(childBlocks, widgets[index]?.blocks, `${target}.${parentWidgetTarget}.${TargetKeys.Blocks}.${TargetKeys.WidgetId}`, widgets[index]?.blocks, parentWidgetType);  
        }
        widgetData.push({ ...editorWidget, id: widgets[index][key], target: `${target}.${parentWidgetTarget}`, widgets: childWidgets, blocks: childBlocks });

      } else {
        // When layout is one column and widget type is upsell, then it behave like widget that stores blocks.
        if ((editorWidget?.blocks || (widgets[index].type === WidgetType.Upsell &&  [Layouts.ONE_COLUMN,Layouts.SMALL_LAYOUT_ONE,Layouts.SMALL_LAYOUT_TWO].includes(widgets[index].upsellInfo.layout))) && widgets[index]?.blocks) {
          let widgetBlocks = [];
          // update the parent widget type to check the condition of its children blocks.
          if(widgets[index].type === WidgetType.Upsell &&  [Layouts.ONE_COLUMN,Layouts.SMALL_LAYOUT_ONE,Layouts.SMALL_LAYOUT_TWO].includes(widgets[index].upsellInfo.layout)) {
            parentWidgetType = null;
          }
          if (ThreeSectionLayout.includes(widgets[index].type)) {
            widgetBlocks = getBlocksFromWidgets(widgets);
          } else {
            widgetBlocks = widgets[index].blocks
          }
          const editorBlocks = structuredClone(editorWidget.blocks);
          const newBlocks = getBlocks(editorBlocks, widgets[index].blocks, `${targetWidgetId}.${TargetKeys.Blocks}.${TargetKeys.Id}`, widgetBlocks, parentWidgetType);

          widgetData.push({ ...editorWidget, id: widgets[index][key], target: `${targetWidgetId}`, blocks: newBlocks, displayOrder: widgets[index]?.displayOrder ?? 0 });
        } else {
          if (widgets[index].type === WidgetType.Banner && editorWidget) {
            editorWidget.icon = widgets[index]?.status?.toLowerCase() + '.svg';
          }
          widgetData.push({ ...editorWidget, id: widgets[index][key], target: `${targetWidgetId}`, displayOrder: widgets[index]?.displayOrder ?? 0 });
        }
      }
    }
  }
  return widgetData;
}

/**
 * Get widget blocks data
 * @param {object[]} blockEditorSchemaArray - The preview object.
 * @param {object[]} blocks - The widget list object.
 * @param {string} target - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {string} newTarget - The key string of new target path
 * @param {string} key - The key to match
 * @returns {object[]} - Block data object
 */
const getBlocks = (blockEditorSchemaArray, blocks, target, widgetBlocks, parentWidget) => {
  let blockData = [];
  // Check the condition of the block in the editor schema
  const blockEditorSchema = getConditionalBlocks(widgetBlocks, blockEditorSchemaArray, parentWidget, TargetConditionTypes.EditorSchema)
  let blockEditorArrayClone = cloneDeep(blockEditorSchema);

  if (blocks.length > 0) {
    for (let index = 0; index < blocks.length; index++) {
      const blockInfo = blocks[index];

      if (!blockInfo) {
        continue;
      }

      let blockEditorClone = blockEditorArrayClone.find((item) => item.type === blockInfo?.type);

      if (!blockEditorClone) {
        continue;
      }

      const matchCount = (widgetBlocks.filter((item) => item && item.type === blockInfo?.type) || []).length;
      blockEditorClone.isAdded = true;

      if (blockInfo?.location) {
        blockEditorClone.location = blockInfo.location;
      }

      blockData.push({ ...blockEditorClone, id: blockInfo['id'], target: `${target}`, count: matchCount });
    }
  }
  return blockData;
}

// get unique blocks from every Widgets and add in one array
export const getBlocksFromWidgets = (widgets, isUniqueBlocks = false) => {
  const blockArray = [];

  widgets.forEach((widget) => {
    if (widget?.blocks?.length > 0) {
      widget.blocks.forEach((block) => {
        if (isUniqueBlocks) { 
          // Check if the block's type is not already in blockArray
          if(!blockArray.some(existingBlock => existingBlock.type === block.type)) {
            blockArray.push(block);
          }
        } else {
          blockArray.push(block);
        }
      });
    }
  });

  return blockArray;
}

/**
 * Get selected widget block data
 * @param {object} targetedWidget - The targeted widget object
 * @param {object[]} editorWidgets - The widget data object.
 * @param {string} field - Field name of targeted widget
 * @returns {object} - Selected widget block data object
 */
export function getSelectedWidget(targetedWidget, editorWidgets, previewType, parentWidgetType, field = TargetKeys.SectionName) {
  let selectedData;

  const isPostPurchase = previewType && previewType === PreviewType.POST_PURCHASE;
  let editorSchemaClone = cloneDeep(editorWidgets);

  for (let index = 0; index < editorSchemaClone.length; index++) {
    if ((editorSchemaClone[index].type === targetedWidget?.type && (!targetedWidget.blockSettings || field === ConditionalKeys.BlockName)) &&
      ((targetedWidget.location && (targetedWidget.location === editorSchemaClone[index].location)) || isPostPurchase || !targetedWidget.location)) {

      // If the targeted widget has a parentType and the addSectionOptions includes sectionName, then locate the widget and update the sectionName.
      const selectedWidget = editorSchemaClone.find((item) => item.type === targetedWidget?.parentType);
      if (targetedWidget?.parentType && selectedWidget?.addSectionOptions) {
        editorSchemaClone = updateSchemaProperties(selectedWidget.addSectionOptions, editorSchemaClone);
      }

      selectedData = { ...editorSchemaClone[index], title: editorSchemaClone[index][field] };
      if(Object.values(UpsellBlocks).includes(editorSchemaClone[index].type) && editorSchemaClone[index]?.overrideWidgets?.includes(parentWidgetType)) {
        for (const override of editorSchemaClone[index].overrides) {
          if (override.Widget === parentWidgetType) {
            if (override.count === 0) continue; // Skip the block if count is 0.
            selectedData = {...getConditionOverrides(override, editorSchemaClone[index])}
          }
        }
      }
      break;
    } else if (editorSchemaClone[index].blocks) {
      selectedData = getSelectedWidget(targetedWidget, editorSchemaClone[index].blocks, previewType, parentWidgetType, ConditionalKeys.BlockName);
      if (selectedData) {
        if (!isPostPurchase) {
          selectedData = { ...selectedData, title: editorSchemaClone[index][field] };
        }
        break;
      }
    } else if (ThreeSectionLayout.includes(targetedWidget?.type)) {
      selectedData = { ...targetedWidget }
    }
  }
  return selectedData;
}

/**
 * @param {*} defaultBlocks - The default block data.
 * @param {*} editorBlocks - The editor block data.
 * @param {*} parentType - The parent widget type.
 * @param {*} returnType - The return type to check editor blocks return or default blocks return.
 * @returns filterd block data after checking condation.
 */
export const getConditionalBlocks = (defaultBlocks, editorBlocks, parentType, returnType) => {
  const defaultBlockData = [];
  const cloneEditorBlock = cloneDeep(editorBlocks);
  const cloneDefaultBlock = cloneDeep(defaultBlocks);

  for (const editorBlock of cloneEditorBlock) {
    for (const defaultBlock of cloneDefaultBlock) {
      if (editorBlock.type === defaultBlock.type) {
        if (editorBlock?.overrides && editorBlock?.overrideWidgets.includes(parentType)) {
          for (const override of editorBlock.overrides) {
            if (override.Widget === parentType) {
              if (override.count === 0) continue; // Skip the block if count is 0.

              const blockOverride = returnType === TargetConditionTypes.EditorSchema
               ? {...editorBlock,...getConditionOverrides(override, editorBlock) }
                : updateDefaultBlock(defaultBlock, editorBlock, parentType);

              defaultBlockData.push(blockOverride);
            }
          }
        } else {
          defaultBlockData.push(returnType === TargetConditionTypes.EditorSchema ? editorBlock : updateDefaultBlock(defaultBlock, editorBlock, parentType));
        }
      }
    }
  }
  return defaultBlockData;
};

/**
 * update the default block data after checking condition.
 **/
const updateDefaultBlock = (defaultBlock, editorBlock, parentType) => {
  if (editorBlock?.replaceDefaultValue) {
    editorBlock.replaceDefaultValue.forEach(({ key, value, parentType: replaceParentType }) => {
      if (replaceParentType === parentType) {
        defaultBlock = updateValue(defaultBlock, key, value);
      }
    });
  }
  return defaultBlock;
}

const updateValue = (defaultBlock, key, value) => {
  for (let nestedKey in defaultBlock) {
    if (nestedKey === key) {
      defaultBlock[nestedKey] = value;
    } else if (typeof defaultBlock[nestedKey] === TargetKeys.Object) {
      updateValue(defaultBlock[nestedKey], key, value)
    }
  }
  return defaultBlock;
}

/**
 * @param {*} override - The override object.
 * @returns 
 */
const getConditionOverrides = (override, editorBlock) => {
  if (!override) return editorBlock;
  const result = {};

  for (const key in editorBlock) {
    result[key] = override[key] !== undefined ? override[key] : editorBlock[key];
  }

  return result;
};

/**
 * Get widget group data
 * @param {object[]} defaultWidgets -  The preview object.
 * @param {object[]} widget - The widget data object.
 * @returns {object[]} - The widget data object.
 */
export function getGroupData(defaultWidgets, widget, parentType, editorSchema, previewType) {
  
  let groupData = [];
  const cloneEditorData = cloneDeep(editorSchema);
  for (let index = 0; index < widget.length; index++) {
    for (let innerIndex = 0; innerIndex < defaultWidgets.length; innerIndex++) {
      if (widget[index].type === defaultWidgets[innerIndex].type) {
        let cloneData = cloneDeep(defaultWidgets[innerIndex]);
        /***
         * When added an Upsell widget inside a multi-upsell widget, we need to update the default value of "noOfProducts" to 1 because only one product display in upsell widget, 
         * But the default value of "noOfProducts" is 4 for bundle and recomandations.
         *  */
        if (cloneData.type === WidgetType.Upsell) {
          cloneData.upsellInfo.noOfProducts = widget[index].noOfProducts ?? cloneData.upsellInfo.noOfProducts;
          // Add default type of "layout" according to the parent widget type.
          cloneData.upsellInfo.layout = DefaultLayoutForWidget[previewType][parentType] ?? cloneData.upsellInfo.layout;
          
          // Remove noOfProducts and layout field from upsell when parent widget type is bundle, because these properties manage from bundle.
          if(parentType === WidgetType.Bundle) {
            delete cloneData.upsellInfo.noOfProducts;
            delete cloneData.upsellInfo.layout;
            cloneData.upsellInfo.subLayout = Layouts.SMALL_LAYOUT_ONE;   
          }
          
          const blocks = cloneEditorData.find(e => e.type === cloneData.type)?.blocks;
          cloneData.blocks = cloneData?.blocks?.map(e => ({ ...e, id: Ids() })) || [];
          cloneData.blocks = getConditionalBlocks(cloneData.blocks, blocks, parentType);
        }

        if(parentType === WidgetType.Bundle) {
          cloneData.displayOrder = widget[index].displayOrder ?? cloneData.displayOrder;
        }
        // If text key is passed to override default text block's text then use text key
        if (cloneData.type === WidgetType.Text) {
          cloneData.text = widget[index].text ?? cloneData.text
        }
        // Overriding Default Blocks for Recommendations
        if (parentType === WidgetType.Recommendations) {
          if (cloneData.type === WidgetType.Upsell && [PreviewType.CHECKOUT, PreviewType.THANK_YOU_PAGE, PreviewType.ORDER_STATUS_PAGE, PreviewType.POST_PURCHASE].includes(previewType)) {
            cloneData.blocks = cloneData?.blocks?.map((e) => {
              if (e.type === UpsellBlocks.ProductDetails) {
                return undefined
              }
              if (e.type === UpsellBlocks.Description) {
                return { ...e, displayProductDescription: false }
              }
              else return { ...e }
            })
            cloneData.blocks = cloneData.blocks.filter((e) => e)
          }

          if (cloneData.type === WidgetType.Upsell && [PreviewType.CHECKOUT].includes(previewType)) {
            cloneData.blocks = cloneData?.blocks?.map((e) => {
              if (e.type === UpsellBlocks.SuccessErrorBanner) {
                return { ...e, successBanner: { ...e.successBanner, text: "Added to cart! " } }
              }
              else return { ...e }
            })
          }

          if (cloneData.type === WidgetType.Upsell && [PreviewType.THANK_YOU_PAGE, PreviewType.ORDER_STATUS_PAGE, PreviewType.POST_PURCHASE].includes(previewType)) {
            cloneData.blocks = cloneData?.blocks?.map((e) => {
              if (e.type === UpsellBlocks.SuccessErrorBanner) {
                return undefined
              }
              else return { ...e }
            })
            cloneData.blocks = cloneData.blocks.filter((e) => e)
          }

          if (cloneData.type === WidgetType.Upsell && [PreviewType.POST_PURCHASE].includes(previewType)) {
            cloneData.blocks = cloneData?.blocks?.map((e) => {
              if (e.type === UpsellBlocks.OrderSummary) {
                return undefined
              }
              if (e.type === UpsellBlocks.DeclineButton) {
                return { ...e, secondClick: true }
              }
              else return { ...e }
            })
            cloneData.blocks = cloneData.blocks.filter((e) => e)
          }

        }

        cloneData.parentType = parentType;
        cloneData.id = Ids();
        groupData.push(cloneData);
        break;
      }
    }
  }
  return groupData;
}

/**
 * Check given condition is satisfied with target key and widgets array or not
 * @param {object[]} widgets - Array of widgets
 * @param {string} target - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {object} condition - Object condition to show the field if condition satisfied
 * @param {string} widgetId - Id of widget to replace place holder in target
 * @param {string} parentWidgetId - Id of parent widget to replace place holder in target
 * @param {string} _type - Type of sections to replace place holder in target
 * @returns {boolean} True if condition is satisfied else False
 */
export const checkTargetConditions = ({widgets, target, conditions, widgetId, parentWidgetId, _type, id, targetedindex, isMobileView = false}) => {
  let isSatisfy = true;
  if (Array.isArray(conditions)) {
    // loop through all conditions
    for (let condition of conditions) {
      // if key have multiple layer, get the targeted value
      let { type, key, operator, value } = condition;
      let index = 1, conditionSatisfy = true;
      if (key?.includes('.')) {
        index = key.split('.').length;
        if (type === TargetConditionTypes.Single || type === TargetConditionTypes.Multiple) {
          key = getTargetString(key, index, index - 1);
        }
      }

      operator = operator ? operator : LogicalOperators.And;
      const data = getTargeted(widgets, target, index, widgetId, parentWidgetId, _type, id, index, targetedindex);

      if (type === TargetConditionTypes.Single && data?.[key] !== value) {
        // if data does not have the exact value, do not show field
        conditionSatisfy = false;
      } else if (type === TargetConditionTypes.Multiple && !value.includes(data?.[key])) {
        // for multiple type, if data does not match value in the value array, do not show field
        conditionSatisfy = false;
      } else if (type === TargetConditionTypes.MultiKey) {
        // for multiple keys if data need to find outer object use this logic
        // in this logic get outer object and go through every key and find targeted value
        let newTarget = getTargetString(target, key.length - 1, key.length - 1);
        let targetedData = getTargeted(widgets, newTarget, 1, widgetId, parentWidgetId, _type, id, 1, targetedindex);
        if (targetedData) {
          for (let index = 0; index < key.length; index++) {
            targetedData = targetedData[key[index]];
          }
          if (targetedData !== value) {
            conditionSatisfy = false;
          }
        } else {
          conditionSatisfy = false;
        }
      } else if (type === TargetConditionTypes.OuterTarget) {
        let newTarget = '';
        // match target value from parent product upsell
        if (ProductDisplayTypes.includes(value)) {
          newTarget = key;
          const targetValue = getTargeted(widgets, newTarget, 0, widgetId, parentWidgetId, _type, id, 1, targetedindex)
          if (!parentWidgetId) {
            // If upsell has one column layout then change the target to widget id
            newTarget = newTarget.replace(TargetKeys.TopWidgetId, TargetKeys.WidgetId);
          } else if(parentWidgetId && !targetValue) {
            // If upsell is inside another widget then change the target
            newTarget = newTarget.replace(TargetKeys.TopWidgetId, `${TargetKeys.TopWidgetId}.${TargetKeys.Widgets}.${TargetKeys.WidgetId}`);
          }
        }
        //match target value from outside of block
        else {
          newTarget = getTargetString(target, index, index);
          newTarget = newTarget + '.' + key;
        }
        let targetValue = getTargeted(widgets, newTarget, 0, widgetId, parentWidgetId, _type, id, 1, targetedindex)
        if (targetValue !== value) {
          conditionSatisfy = false;
        }
      } else if (type === TargetConditionTypes.Parent) {
        // If post product upsell has one column layout then change the target to widget id
        let target = `${TargetKeys.Widgets}.${TargetKeys.WidgetId}`;
        if(parentWidgetId && widgetId) {
          target = `${TargetKeys.Widgets}.${TargetKeys.TopWidgetId}.${TargetKeys.Widgets}.${TargetKeys.WidgetId}`;
        } else if (parentWidgetId) {
          target = `${TargetKeys.Widgets}.${TargetKeys.TopWidgetId}`;
        }
        const parentData = getTargeted(widgets, target, 0, widgetId, parentWidgetId, _type, id, 1, targetedindex);
        if (!value.includes(parentData?.[key])) {
          conditionSatisfy = false;
        }
      } else if (type === TargetConditionTypes.SuperParent) {
        let target = `${TargetKeys.Widgets}.${TargetKeys.WidgetId}`;
        const parentData = getTargeted(widgets, target, 0, parentWidgetId, parentWidgetId, _type, id, 1, targetedindex);
        if (!value.includes(parentData?.[key])) {
          conditionSatisfy = false;
        }
      } else if (type === TargetConditionTypes.ViewType) {
        if (value === ViewType.mobile && !isMobileView) {
          conditionSatisfy = false;
        } else if (value === ViewType.desktop && isMobileView) {
          conditionSatisfy = false;
        }
      }

      if (operator === LogicalOperators.And) {
        isSatisfy = isSatisfy && conditionSatisfy;
      } else if (operator === LogicalOperators.Or) {
        isSatisfy = isSatisfy || conditionSatisfy;
      }
    }

    return isSatisfy;
  }
}

/**
 * Checks whether field is allowed to be updated or not based on conditions. 
 * @param {object} widget - The preview object.
 * @param {string} target - The key string of target path eg. 'widgets.[id:{WIDGET_ID}]...'
 * @param {object[]} updateConditions - The list of conditions.
 * @param {string} widgetId - The id of the block's widget
 * @param {string} parentWidgetId - The id of the block's parent widget
 * @returns {boolean} Returns true if conditions are satisfied otherwise returns false.
 */
export const canUpdateField = (widget, target, updateConditions, widgetId, parentWidgetId, type, id) => {
  if (updateConditions == null) return true;

  const data = getTargeted(widget, target, 0, widgetId, parentWidgetId, type, id)

  return evaluateAND(updateConditions, data);
}

/**
 * Performs OR operation on all the provided conditions.
 * @param {object[]} conditions - The list of conditions.
 * @param {object} data - The targeted preview object.
 * @example
 * // returns true
  evaluateOR([
    {
      'type': 'equal',
      'key': 'blockSettings.compareTo.isEnable',
      'value': true
    }
  ], data);
 * @returns {boolean} Returns true if any condition is true otherwise returns false.
 */
const evaluateOR = (conditions, data) => {
  for (const condition of conditions) {
    if (LogicalOperators.Or in condition) {
      const orCondition = evaluateOR(condition[LogicalOperators.Or], data);
      if (orCondition) return true;
    } else if (LogicalOperators.And in condition) {
      const andCondition = evaluateAND(condition[LogicalOperators.And], data);
      if (andCondition) return true;
    } else {
      const { type, key, value } = condition;
      if (evaluateCondition({ value: getNestedObjectValue(data, key), compareValue: value, operator: type })) return true;
    }
  }

  return false;
}

/**
 * Performs AND operation on all the provided conditions.
 * @param {object[]} conditions - The list of conditions.
 * @param {object} data - The targeted preview object.
 * @example
 * // returns true
  evaluateAND([
    {
      'type': 'equal',
      'key': 'blockSettings.compareTo.isEnable',
      'value': true
    }
  ], data);
 * @returns {boolean} Returns true if all conditions are true otherwise returns false.
 */
const evaluateAND = (conditions, data) => {
  for (const condition of conditions) {
    if (LogicalOperators.Or in condition) {
      const orCondition = evaluateOR(condition[LogicalOperators.Or], data);
      if (!orCondition) return false;
    } else if (LogicalOperators.And in condition) {
      const andCondition = evaluateAND(condition[LogicalOperators.And], data);
      if (!andCondition) return false;
    } else {
      const { type, key, value } = condition;
      if (!evaluateCondition({ value: getNestedObjectValue(data, key), compareValue: value, operator: type })) return false;
    }
  }

  return true;
}

/**
 * Evaluate whether data value and conditional value satisfy the operation or not. 
 * @param {object} condition - The condition object for comparing value according to operator(value, compareValue, operator).
 * @returns {boolean} Returns true if conditions are satisfied otherwise returns false.
 */
const evaluateCondition = (condition) => {
  const { value, compareValue, operator } = condition;
  switch (operator) {
    case ConditionalOperators.Equal:
      return value === compareValue;
    case ConditionalOperators.NotEqual:
      return value !== compareValue;
    case ConditionalOperators.GreaterThan:
      return value > compareValue;
    case ConditionalOperators.LessThan:
      return value < compareValue;
    case ConditionalOperators.GreaterThanEqual:
      return value >= compareValue;
    case ConditionalOperators.LessThanEqual:
      return value <= compareValue;
    default:
      throw new Error(ConditionalOperators.InvalidOperator + operator);
  }
};

/**
 * Get value from data object for a nested key string.
 * @param {object} data - The data object from which value needs to be retrieved.
 * @param {string} nestedKeyString - The nested key string.
 * @example
 * // returns value of data[blockSettings][originalPrice][isEnable]
 * getNestedObjectValue(data, 'blockSettings.originalPrice.isEnable');
 * @returns {any} Returns value from data object for given nested key.
 */
const getNestedObjectValue = (data, nestedKeyString) => {
  const keys = nestedKeyString.split('.');

  for (let key of keys) {
    data = data[key];
  }

  return data;
}

/**
 * For generating right bar link data
 * @param {object[]} data - block data object
 * @param {string} parentWidgetId - parent widget id
 * @param {string} type - The id of the block's parent widget
 * @param {boolean} isSeparators - 
 * @returns {object[]} - block data object
 */
export const makeRightBarLinks = (data, parentWidgetId, type = TargetKeys.Section, isSeparators) => {
  let cloneData = cloneDeep(data);
  let result = [];
  let rightBarLinkData = {};
  // Check if layout left right or bottom exit or not
  const layoutsExist = ThreeSectionLayout.some(layout => cloneData.some(item => Object.values(item).includes(layout)));

  if (layoutsExist) {
    // Get all blocks from layouts
    cloneData = getBlocksFromWidgets(cloneData);
    type = TargetKeys.Block;
  }

  if (isSeparators) {
    cloneData = cloneData.sort((a, b) => a.location - b.location);
  }

  for (let index = 0; index < cloneData.length; index++) {
    const widgetType = cloneData[index].type;

    if ((widgetType !== WidgetType.Divider && type === TargetKeys.Section) || (cloneData[index].count !== 0 && type !== TargetKeys.Section)) {
      let suffix = '';

      if (!rightBarLinkData[TargetKeys.Max + widgetType]) {
        const count = cloneData.filter((x) => x && x.type === widgetType).length;

        if (count > 1) {
          rightBarLinkData[widgetType] = 1;
        }
        rightBarLinkData[TargetKeys.Max + widgetType] = count;
      }

      if (rightBarLinkData[widgetType]) {
        suffix = `(${rightBarLinkData[widgetType]})`;
        rightBarLinkData[widgetType] += 1;
      }

      cloneData[index].name = `${t('edit')} ${t(cloneData[index][type + TargetKeys.Name]?.toLowerCase())?.toLowerCase()} ${suffix}`;
      // If check layout left right or bottom exit the add parent layout id
      if (layoutsExist) {
        // Get parent layout id from data
        let parentLayout = data.find(item => BlockLocation[item.type] === cloneData[index].location);
        if (!parentLayout) {
          parentLayout = data.find(item => BlockLocation[item.type] === 2)
        }
        cloneData[index].parentSectionID = parentLayout.id;
      } else {
        cloneData[index].parentSectionID = parentWidgetId;
      }
      result.push(cloneDeep(cloneData[index]));
    }
  }
  return result;
}

/**
 * For fetching widget options
 * @param {object[]} data - Widget data object
 * @param {object[]} widgets - Widget options
 * @returns {object[]} - Updated widget data
 */
export const getOptions = (data, widgets) => {
  const options = [];

  if (widgets.length > 0) {
    for (let index = 0; index < data.length; index++) {
      if (data[index].skipOption) continue;
      const indexPosition = widgets.findIndex((item) => item.type === data[index].type);
      if (indexPosition >= 0)
        options.push({ ...widgets[indexPosition], sectionName: data[index].sectionName ?? widgets[indexPosition].sectionName, icon: data[index].icon });
    }
  }
  return options;
}

export const getUniqueUpsellName = (name, type, widgets) => {
  const maxCount = widgets?.reduce(function (prev, curr) {
    if (type === curr.type) {
      let value = Number(curr.name.split('(').pop().split(')')[0])
      if (value) return prev > value ? prev : value;
      else return prev
    } else {
      return prev
    }
  }, 0);
  const index = name.indexOf('(')
  return setCharAt(name, index + 1, Number(maxCount) + 1);
}

export const setCharAt = (str, index, character) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + character + str.substring(index + 1);
}

//for managing layout change in post purchase
export const changeLayout = (widget, newLayout, oldLayout) => {
  try {
    let blockArray = []

    if ([Layouts.ONE_COLUMN, Layouts.SMALL_LAYOUT_ONE, Layouts.SMALL_LAYOUT_TWO].includes(oldLayout)) {
      blockArray = widget?.blocks
    } else if (oldLayout === Layouts.TWO_COLUMN || oldLayout === Layouts.THREE_COLUMN) {
      if (widget?.widgets?.length > 0) {
        widget?.widgets.forEach((widget) => {
          if (widget?.blocks?.length > 0) {
            blockArray = blockArray.concat(widget.blocks)
          }
        })
      }
    }

    if ([Layouts.ONE_COLUMN, Layouts.SMALL_LAYOUT_ONE, Layouts.SMALL_LAYOUT_TWO].includes(newLayout)) {
      delete widget?.widgets;
      widget.blocks = blockArray;
    } else if (newLayout === Layouts.TWO_COLUMN || newLayout === Layouts.THREE_COLUMN) {
      if (blockArray.length > 0) {
        const layout = newLayout === Layouts.THREE_COLUMN ? ThreeSectionLayout : TwoSectionLayout;
        let listBlocks = {};
        layout.forEach(key => listBlocks[key] = []);

        blockArray?.forEach((block) => {
          if (block.location === 1) {
            listBlocks.LEFT.push(block)
          } else if (block.location === 2) {
            listBlocks.RIGHT.push(block)
          } else {
            if (newLayout === Layouts.THREE_COLUMN) {
              listBlocks.BOTTOM.push(block)
            } else {
              listBlocks.RIGHT.push(block)
            }
          }
        })

        blockArray = getSectionsByList(layout, listBlocks, widget)
      }
      delete widget?.blocks;
      widget.widgets = blockArray;
    }

    return widget;
  } catch (err) {
    console.warn('err', err);
  }
}

export const getSectionsByList = (layout, list, selectedWidget) => {
  const widgetArray = []

  layout.forEach((key) => {
    const widget = { ...ThreeSectionLayoutSettings[key], id: Ids(), blocks: list[key], isVisible: selectedWidget.upsellInfo?.[ThreeSectionLayoutVisible[key]], parentType: selectedWidget.type };
    widgetArray.push(widget);
  })

  return widgetArray
}

export function CreatedNewObj(state) {
  const { ...rest } = state
  let data = JSON.stringify(rest);
  data = JSON.parse(data);
  return data;
}

/**
 * 
 * @param {Array[]} widgetsOrBlocks - array of blocks or widhgets
 * @param {Object{}} updatedSettings - updated settings object include all widgets
 * @param {string} target - target for get the targeted blocks or widgets
 * @param {string or number} value - set the value for the targeted blocks or widgets
 * @param {string} parentWidgetId - parent widget id
 * @param {string} widgetId - widget id
 * @param {string} widgetOrBlockType - which type of the component are selected
 * @param {boolean} isSyncSettings - sync settings for same blocks or widgets
 * @returns 
 */
const syncSettings = (widgetsOrBlocks, updatedSettings, target, value, parentWidgetId, widgetId, widgetOrBlockType, isSyncSettings, isWidget = false) => {
  // Update the value for same blocks or widgets
  if (isSyncSettings) {
    widgetsOrBlocks.forEach((widgetOrBlock) => {
      if (widgetOrBlock.type === widgetOrBlockType) {
        updatedSettings = updateTargetedValue(updatedSettings, target, value, isWidget ? widgetOrBlock.id : widgetId, parentWidgetId, widgetOrBlockType, widgetOrBlock.id);
      }
    })
  }
  return updatedSettings;
}

export const syncComponentSettings = (updatedSettings, target, value, parentWidgetId, widgetId, widgetOrBlockType, isSyncSettings) => {
  // Get all the widgets types
  const widgetTypes = Object.keys(WidgetType).map(key => WidgetType[key]);
  // To set the target for get the targeted blocks or widgets
  let parentTarget = 'widgets';
  if (widgetTypes.includes(widgetOrBlockType)) {
    parentTarget = `widgets`;
  } else if ((parentWidgetId && widgetId)) {
    parentTarget = `widgets.${TargetKeys.TopWidgetId}`
  } else if (widgetId) {
    parentTarget = `widgets.${TargetKeys.WidgetId}`
  }
  // To get the target value from the updated settings to sync settings for components
  const widget = getTargetedValue(updatedSettings, parentTarget, widgetId, parentWidgetId);

  // To sync settings for same blocks and top widgets

  if (widget?.length > 0 && widgetTypes.includes(widgetOrBlockType)) {
    updatedSettings = syncSettings(widget, updatedSettings, target, value, parentWidgetId, widgetId, widgetOrBlockType, isSyncSettings, true);
  } else if (widget?.blocks?.length > 0) {
    updatedSettings = syncSettings(widget.blocks, updatedSettings, target, value, parentWidgetId, widget.id, widgetOrBlockType, isSyncSettings);
  } 
  if (widget?.widgets?.length > 0) {
    widget.widgets.forEach((widget) => {
      if (widget?.blocks?.length > 0) {
        updatedSettings = syncSettings(widget.blocks, updatedSettings, target, value, parentWidgetId, widget.id, widgetOrBlockType, isSyncSettings);
      }
    })
  }

  return updatedSettings;
}

/**
 * 
 * @param {Object} setting - selected component settings
 * @param {Object} widget - latest widget object
 * @param {string} id - Block id
 * @param {string} widgetId - widget id
 * @param {string} parentWidgetId - parent widget id
 * @returns 
 */
export const getConditionalOption = (setting, widget, id, widgetId, parentWidgetId) => {
  if (setting?.conditionalOption) {
    let settingTypeTarget = setting.settingTypeTarget;
    let conditionOptionTarget = setting.conditionOptionTarget;
    // If upsell has no any column layout then change the target to widget id
    if (!parentWidgetId) {
      settingTypeTarget = settingTypeTarget.replace(TargetKeys.TopWidgetId, TargetKeys.WidgetId);
      conditionOptionTarget = conditionOptionTarget.replace(TargetKeys.TopWidgetId, TargetKeys.WidgetId);
    }
    const type = getTargetedValue(widget, settingTypeTarget, widgetId, parentWidgetId);
    const sectionSettingsData = getTargeted(widget, conditionOptionTarget, 0, widgetId, parentWidgetId, type, id);
    return sectionSettingsData?.variants?.map((option) => {
      return {
        label: option.title,
        value: `${option.id}`
      }
    })
  }
  return setting?.options;
}

// return formatted date for events
export const getFormatDateandTime = () => {
  const currentDate = new Date(Date.now());
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * @param {*} editorSchema - editor schem json object
 * @param {*} previewType - preview type example: ORDER_STATUS_PAGE, THANK_YOU_PAGE, CHECKOUT, POST_PURCHASE
 * @param {*} storeInfoRes - store info response
 * @returns edited editor schema
 */
export const editEditorSchema = (editorSchema, previewType, storeInfoRes) => {
  /* TYP/OSP
  order edit = 1 -> Draft order - Show all shipping details settings
  order edit = 0 -> Edit order - Dont show any shipping details settings at all
  */
  if ([PreviewType.ORDER_STATUS_PAGE, PreviewType.THANK_YOU_PAGE].includes(previewType) && storeInfoRes.order_edit === '0') {
    const widgets = editorSchema.widgets;
    const priceDiscountBlock = widgets.find((widget) => widget.type === WidgetType.Upsell).blocks.find((block) => block.type === UpsellBlocks.PriceAndDiscount)
    const shippingDetails = priceDiscountBlock.blockSettings.find((setting) => setting.type ==='shippingDetails')
    if(shippingDetails) {
      shippingDetails.settingGroups = []
    }
  }
  return editorSchema;
}

/*
 * Check the editor is opened from flow builder or direct through url
 */
export const isOpenedFromFlowBuilder = () => {
  const params = window.location.search;
  const fromCondition = new URLSearchParams(params).get(QueryParams.FromCondition);
  return fromCondition === 'true';
}

export const addSegmentEventProperties = (oldConfigSetting, newConfigSetting) => {
    newConfigSetting?.widgets?.forEach((newWidget) => {
      const oldWidget = oldConfigSetting?.widgets.find((oldWidget) => oldWidget.id === newWidget.id);
      if(newWidget.type === WidgetType.Upsell){
        if(oldWidget){
          newWidget.upsellInfo['Old product to display option'] = oldWidget.upsellInfo.type;
          newWidget.upsellInfo['New product to display option'] = newWidget.upsellInfo.type;
        }else { 
          // New upsell widget added and saved so old option always be empty
          newWidget.upsellInfo['Old product to display option'] = '';
          newWidget.upsellInfo['New product to display option'] = newWidget.upsellInfo.type;
        }
      } else if(newWidget.widgets?.length) {
        addSegmentEventProperties(oldWidget, newWidget);
      }
    });

  return newConfigSetting;
}

/**
 * @param {*} unifiedEditor - unified editor schema json 
 * @param {*} latestConfigSettings - latest json of settings
 * @param {*} widgetId - main widgets like UPSELL
 * @param {*} parentWidgetId - all multiProduct widgets
 * @param {*} componentType - Current selected component image , price and discount, quantity, etc
 * @param {*} value - new value to be update in the editor schema
 * @returns - updated unified editor schema with updated settings
 */
export const updateSyncSettings = (unifiedEditor, latestConfigSettings, widgetId, parentWidgetId, componentType, value) => {
  // Implement dynamic sync settings value change logic here
  // For example, when a checkbox value changes, update the corresponding Sync value This function currently works only for blocks
  let parentTarget = TargetKeys.Widgets;
  if ((parentWidgetId && widgetId)) {
    parentTarget = `${TargetKeys.Widgets}.${TargetKeys.TopWidgetId}.${TargetKeys.Widgets}.${TargetKeys.WidgetId}`
  } else if (widgetId) {
    parentTarget = `${TargetKeys.Widgets}.${TargetKeys.WidgetId}`
  }
  
  const selectedWidget = getTargetedValue(latestConfigSettings, parentTarget, widgetId, parentWidgetId);

  unifiedEditor.widgets.forEach((widget) => {
    if(widget.type === selectedWidget.type) {
      widget.blocks.forEach((block) => {
        if(block.type === componentType && block?.overrideWidgets.includes(selectedWidget.parentType)) {
          block.overrides.forEach((override) => {
            if(override.Widget === selectedWidget.parentType) {
              override.syncSettings = value;
            }
          })
        }
      })
    }
  })
  
  return unifiedEditor;
}
/*
 * Check the editor is opened from flow builder for Super Admin
 */
export const isOpenedFromFlowBuilderForSuperAdmin = () => {
  const params = window.location.search;
  const isAdmin = new URLSearchParams(params).get(QueryParams.IsAdmin);
  return isAdmin === 'true';
}

/*
 * Check the editor is opened from the email url
 */
export const shouldOpenEditorFromUrl = () => {
  const params = window.location.search;
  const openEditor = new URLSearchParams(params).get(QueryParams.OpenEditor);
  return openEditor === 'true' || openEditor === 'false'; // open editor from URL only if value is true or false
}

export const useBudleDragAndDropIndexing = (data, updateData, latestConfigSetting)=> {
  const [combineDataIds, setCombineDataIds] = useState([]); // using this for the drag and drop and updating below states to show in UI which is rendring the data
  const [combineData, setCombineData] = useState([]); // using this state for rendring the combine data of block and widgets

  
  useEffect(() => {
      if(data.type !== WidgetType.Bundle) return;
          const allItems = [...(data.widgets || []), ...(data.blocks || [])];
          const isdisplayOrderPresent = allItems.every(item => 'displayOrder' in item);

          // Sort if displayOrder is present, otherwise just map
          const sortedOrUnsorted = isdisplayOrderPresent && (data.type === WidgetType.Bundle)
              ? allItems.sort((a, b) => a.displayOrder - b.displayOrder)
              : allItems;

          setCombineDataIds(sortedOrUnsorted.map(item => item.id));

  }, [data.blocks?.length, data.widgets?.length]);

  useEffect(() => {
      if(data.type !== WidgetType.Bundle) return; 
      const widgetsAndBlocks = [...(data.widgets || []), ...(data.blocks || [])];
      const idToDataMap = new Map(widgetsAndBlocks.map(item => [item.id, item]));

      if (combineDataIds.length) {
          // Lookup each id in the map and filter out undefined values
          const tempData = combineDataIds
              .map(id => idToDataMap.get(id))
              .filter(data => data !== undefined);

          setCombineData(tempData);
      } else {
          setCombineData(widgetsAndBlocks);
      }
  }, [data.blocks, data.widgets, combineDataIds]);


  const handleUpdateDisplayOrder = (combineDataIds)=> {
    if (combineDataIds.length) {
      const blockTargetKey = "widgets.[id:{TOP_WIDGET_ID}].blocks";
      const widgetTargetKey = "widgets.[id:{TOP_WIDGET_ID}].widgets";
      const latestConfig = latestConfigSetting;

      const targetedValue = getTargetedValue(latestConfig, blockTargetKey, '', data?.id) || [];
      const targetedValue2 = getTargetedValue(latestConfig, widgetTargetKey, '', data?.id) || [];

      // Create a map for quick index lookup
      const idToIndexMap = new Map(combineDataIds.map((id, index) => [id, index]));

      // Update displayOrder for targetedValue
      targetedValue.forEach((item) => {
          const index = idToIndexMap.get(item.id);
          if (index !== undefined) {
              item.displayOrder = index;
          }
      });

      // Update displayOrder for targetedValue2
      targetedValue2.forEach((item) => {
          const index = idToIndexMap.get(item.id);
          if (index !== undefined) {
              item.displayOrder = index;
          }
      });
      // Update the data
      updateData(latestConfig);
  }
  }
const handleDragDataForBundle = (result)=> {
  const tempData = [...combineDataIds]
  const selectedBlock = tempData.splice(result.source.index, 1)[0];
  tempData.splice(result.destination.index, 0, selectedBlock);
  handleUpdateDisplayOrder(tempData);
  setCombineDataIds(tempData);
  const comb = [...(data.widgets || []), ...(data.blocks || [])];
  const idToDataMap = new Map(comb.map(item => [item.id, item]));
  const tempCombineData = tempData.map(id => idToDataMap.get(id)).filter(data => data);
  setCombineData(tempCombineData);
}

return {
  combineData, setCombineDataIds, combineDataIds, setCombineData, handleDragDataForBundle
}
}