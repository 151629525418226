import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { Icon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { SearchIcon } from '@chakra-ui/icons';
import { cloneDeep, isEmpty } from 'lodash';
import { t } from 'i18next';

import { CDN_BASE_URL, PLATFORM } from '../../../../../../config';
import { getTargetedValue, updateTargetedValue, getTargetString, CreatedNewObj } from '../../../../../../Utils/Common';
import { PageData } from '../../../../../../ContextAPI/Context';
import { FilterStates } from '../../FilterContext'
import { fetchProductsWithSearch } from '../../../../../../Service/index';
import { PlatformType, WidgetType } from '../../../../../../Constant';
import { Header, Label, TextInput, Spinner, Button, Banner } from '../../../../../../Shared/Components';
import { BannerType } from '../../../../../../Shared/Components/Banner';
import { getProductResponse } from '../Utils/GetProductResponse';
import styles from './ProductSelector.module.css';

const LIMIT = 15;

const SelectProduct = (props) => {

    const { data: product, widgetId, parentWidgetId, target, componentType, setting, index: targetedIndex } = props.data;

    const { filterState } = useContext(FilterStates);
    const { configSetting, updateData, settingType, setRequiredData, requiredData, countryCode } = useContext(PageData);

    const [searchValue, setSearchValue] = useState('');
    const [products, setProducts] = useState([]);
    const [endCursor, setEndCursor] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(product || {});
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [loadingSelectProduct, setLoadingSelectProduct] = useState(false);
    const [error, setError] = useState('');

    const handleClose = useCallback(() => {
        filterState({
            status: false,
            component: ''
        });

        document.querySelector('.right-section').classList.add('overflow_vertical_scroll');
        document.querySelector('.right-section-title')?.classList?.remove('removeDiv');
        document.querySelector('.bottom_container')?.classList?.remove('removeDiv');
        document.querySelector('.links-container')?.classList?.remove('removeDiv');
    }, [filterState]);

    const handleSearchValue = (value) => {
        setSearchValue(value);
        searchProducts(value);
        setProducts([]);
    }

    const selectProduct = (id) => {
        const selectedProduct = products.find(x => x.id === id)
        setSelectedProduct(selectedProduct);
    }
    const latestSetting = useMemo(() => {
        return cloneDeep(configSetting[configSetting.length - 1]);
    }, [configSetting]);

    const handleProductSelect = async () => {
        let isProductExist = false; // Check the product is already exist in the widget then display error message. for bundle and recommendations widget
        try {
            setError('');
            setLoadingSelectProduct(true);
            // const newTarget = target;
            // let widgetTarget = '';
            // if (widgetId && parentWidgetId && targetedIndex >=0) {
            //     widgetTarget = getTargetString(newTarget, 5, 5);
            // }

            // const widget = getTargetedValue(latestSetting, widgetTarget, widgetId, parentWidgetId, settingType,'', targetedIndex);

            // get product id which is the part after last / in selectedProduct.id field
            const productId = selectedProduct.id.substr(selectedProduct.id.lastIndexOf('/') + 1);
            // const productRes = await fetchProduct(productId);
            const productRes = await getProductResponse(productId, PLATFORM, countryCode);
            const cloneSettings = CreatedNewObj(latestSetting);
            
            // Check the product is already exist in the widget then display error message. for bundle and recommendations widget.
            // if (widget && ((widget?.parentType === WidgetType.Recommendations && widget?.upsellInfo?.noOfProducts > 1) || widget?.parentType === WidgetType.Bundle)) {
            //     let products
            //     if(widget?.parentType === WidgetType.Recommendations) {
            //         products = widget.upsellInfo.settings.SPECIFIC_PRODUCT;
            //         isProductExist = products.some(product => product?.productInfo?.id === Number(productId));
            //     } else if(widget?.parentType === WidgetType.Bundle) {
            //         widgetTarget = getTargetString(widgetTarget);
            //         const bundleWidgets = getTargetedValue(latestSetting, widgetTarget, widgetId, parentWidgetId);
            //         products = bundleWidgets.filter(widget => widget.type === WidgetType.Upsell).map(widget => widget.upsellInfo.settings.SPECIFIC_PRODUCT[0].productInfo);
            //         isProductExist = products.some(product => product?.id === Number(productId));
            //     }

            //     if (isProductExist) {
            //         setRequiredData({ ...requiredData, stopChanges: true, toastMessage: `Selected product is already exist in widget`, isDisable: true, showToast: true });
            //         return;
            //     }
            // }

            let updatedSettings = updateTargetedValue(cloneSettings, target, productRes, widgetId, parentWidgetId, settingType, '', targetedIndex);

            if (setting.updateConditions) {
                // update imageQty for image block
                const blockTarget = getTargetString(target, 3, 3);
                const conditionObj = setting.updateConditions;
                let targeted = `${blockTarget}.${conditionObj.targetBlock}`;
                const block = getTargetedValue(latestSetting, targeted, widgetId, parentWidgetId, settingType, '', targetedIndex);

                if (block[conditionObj.conditions.target] !== conditionObj.conditions.value) {
                    updatedSettings = updateTargetedValue(updatedSettings, `${targeted}.${conditionObj.target}`, productRes.data.product?.images?.length, widgetId, parentWidgetId, settingType, '', targetedIndex);
                }
            }
            updateData(updatedSettings);
            filterState({ status: false, component: '' });

            document.querySelector('.right-section-title')?.classList?.remove('removeDiv');
            document.querySelector('.bottom_container')?.classList?.remove('removeDiv');
            document.querySelector('.links-container')?.classList?.remove('removeDiv');
        } catch (error) {
            console.log("Failed to load product", error)
            setError('Failed to load product');
        }
        finally {
            setLoadingSelectProduct(false);
            if(!isProductExist) { // Check the product is already exist. for bundle and recommendations widget
                setRequiredData({ stopChanges: false, showToast: false, showErrorMessage: false, toastMessage: '', isDisable: false });
            }
        }
    }

    const handleCancelSelectedProduct = () => {
        setSelectedProduct({});
        filterState({ status: false, component: '' });
        document.querySelector('.right-section-title')?.classList?.remove('removeDiv');
        document.querySelector('.bottom_container')?.classList?.remove('removeDiv');
        document.querySelector('.links-container')?.classList?.remove('removeDiv');
    }

    const searchProducts = useCallback(async (value) => {
        try {
            setError('');
            setLoadingProducts(true);
            setRequiredData({ ...requiredData, isDisable: true });
            const response = await fetchProductsWithSearch(value, endCursor, LIMIT);
            
            let productList = response.data.products;
            // get the selected widget
            let widgetTarget = target.replace(`.${setting.target}`, '');
            const widget = getTargetedValue(latestSetting, widgetTarget, widgetId, parentWidgetId, settingType,'', targetedIndex);
        
            // filter out products that are physical product for bundle widget
            if (widget?.parentType === WidgetType.Bundle){
                productList = productList.filter((product) => !!product.isPhysical);
            }

            const fetchedProducts = productList.map(product => ({
                ...product,
                image: product.images && product.images.length ? product.images[0] : null
            }));
            const data = response.data;
            const endCursorParams =data?.pageInfo?.endCursor && !value ? data?.pageInfo?.endCursor : null
            setEndCursor(endCursorParams);

            setProducts((prevProducts) => value ? [...fetchedProducts] : [...prevProducts, ...fetchedProducts]);
            setHasNextPage(data?.pageInfo?.hasNextPage);
        } catch (error) {
            setError('Failed to fetch products');
        } finally {
            setLoadingProducts(false);
        }
    },[endCursor]);

    useEffect(() => {
        return () => handleClose()
    }, [componentType, handleClose])

    useEffect(() => {
        searchProducts('');
    }, []);

    const productList =
        products
            .map(({ id, image, title }) => (
                <div key={id} className={`product_item_container ${id === selectedProduct.id ? 'selected_item' : ''}`}>
                    <li className={`product_item ${id === selectedProduct.id ? 'selected' : ''}`} onClick={() => selectProduct(id)}>
                        <div className={`product_image ${styles.image_wrap}`} >
                            <img
                                src={image ? image : CDN_BASE_URL + 'assets/dummy_small.png'}
                                alt={selectedProduct?.title}
                                className={styles.selected_image}
                            />
                        </div>

                        <div className='product-title'>
                            <span>{title}</span>
                        </div>
                    </li>
                </div>
            ));


    return (
        <div className='product_selector_container'>
            <Header label={'select_featured_product'} onClose={handleClose} rootStyles={{ padding: '0.5rem 1rem' }} dividerStyles={{ margin: '0px' }} />

            <div className='product_top_section'>
                <div className='product_search m-3'>
                    <TextInput
                        prefix={PLATFORM === PlatformType.Wix ? <SearchIcon color='gray.300' /> : <Icon source={SearchMinor} color='base' />}
                        placeholder={t('filter')}
                        onChange={e => handleSearchValue(e)}
                        value={searchValue}
                        autoComplete='off'
                    />
                </div>

                <div className='specific_product_list'>
                    <ul>
                        {error && <div className='text-center'>{error}</div>}
                        {!error && loadingProducts && products.length === 0 && <div className='text-center'>
                            <Spinner size='large' label='Loading products' color='cyan.500' thickness='4px' />
                        </div>}
                        {!error && !loadingProducts && products.length === 0 && <div className='text-center'>{t('no_match_found')}</div>}
                        {!error && products.length > 0 && productList}

                        {!error && products.length > 0 && hasNextPage &&
                            <div className='load_more_button_container'>
                                <Button disabled={loadingProducts} fullWidth onClick={() => {searchProducts('')}}>{t('load_more')}</Button>
                            </div>
                        }
                    </ul>
                </div>
            </div>

            <div className='product_bottom_section'>
                {selectedProduct?.id &&
                    <div className='selected_product_container'>
                        <div className={`selected_product_image ${styles.image_wrap}`}>
                            <img
                                src={selectedProduct?.image?.src ? selectedProduct?.image?.src : selectedProduct?.image ? selectedProduct?.image : CDN_BASE_URL + 'assets/dummy_small.png'}
                                alt={selectedProduct?.title}
                                className={styles.selected_image}
                            />
                        </div>
                        <div className='product-title'>
                            <span className='product_subtitle'>Currently selected</span><br />
                            <span>{selectedProduct.title || selectedProduct.name}</span>
                        </div>
                    </div>
                }

                <div className='product_selector_controls custom-button'>
                    <Button onClick={handleCancelSelectedProduct} color='teal' variant='secondary' >{t('cancel')}</Button>
                    <Button disabled={!selectedProduct?.id} primary onClick={handleProductSelect} color='teal' isLoading={loadingSelectProduct}>
                        {t('select')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const ProductSelectedData = (props) => {
    const { data, target, widgetId, parentWidgetId, index } = props
    const { configSetting, updateData, settingType } = useContext(PageData);
    const { filterState } = useContext(FilterStates);

    const changeProduct = () => {
        filterState({
            status: true,
            component: <SelectProduct data={{ ...props }} />
        });

        document.querySelector('.right-section').classList.remove('overflow_vertical_scroll');
        document.querySelector('.right-section-title')?.classList?.add('removeDiv');
        document.querySelector('.bottom_container')?.classList?.add('removeDiv');
        document.querySelector('.links-container')?.classList?.add('removeDiv');
    }

    const deleteProduct = () => {
        const latestSettings = CreatedNewObj(configSetting[configSetting.length - 1]);
        const updatedSettings = updateTargetedValue(latestSettings, target, {}, widgetId, parentWidgetId, settingType,'', index);
        updateData(updatedSettings);
    }

    return (
        <>
            <div className='flex-list'>
                <div className='product-image'>
                    <img src={data?.image?.src ? data.image.src : CDN_BASE_URL + 'assets/dummy.png'} alt={'Product'} />
                </div>
                <div className='product-title'>
                    <span >{data?.title || data?.name}</span>
                </div>
            </div>
            <div className='flex-list m-3' style={{ justifyContent: 'center' }}>
                <div >
                    <Button onClick={changeProduct} color='teal' variant='outline'>{t('change')}</Button>
                </div>
                <div className='product-title'>
                    <Button onClick={deleteProduct} color='teal' variant='outline'>{t('delete')}</Button>
                </div>
            </div>
        </>
    )
}

const ProductSelector = (props) => {
    const { setting, widgetId, parentWidgetId, target, index } = props.data
    const { filterIsOpen, filterState } = useContext(FilterStates);
    const { configSetting, setRequiredData, requiredData, settingType, isSelectProduct, setIsSelectProduct } = useContext(PageData);
    const [productData, setProductData] = useState();

    useEffect(() => {
        const productData = getTargetedValue(configSetting[configSetting.length - 1], target, widgetId, parentWidgetId, settingType,'', index);
        // TODO: pending product data api call, Check product id exist in preview json or not
        // const productId = '7589456235';
        // const productRes = await fetchProduct(productId);

        setProductData(productData);
        if (isEmpty(productData) && setting.isRequired) {
            setRequiredData({ ...requiredData, stopChanges: true, toastMessage: 'You need to select a product', isDisable: true });
        }

        return () => {
            setRequiredData({ stopChanges: false, showToast: false, showErrorMessage: false, toastMessage: '', isDisable: false });
        }
         
    }, [configSetting, target, widgetId, parentWidgetId, settingType, setting.isRequired]);

    useEffect(() => {
        if (isSelectProduct) {
            changeFont()
            setIsSelectProduct(false)
        }
    }, [isSelectProduct])

    const changeFont = () => {
        document.querySelector('.right-section-title')?.classList?.add('removeDiv');
        document.querySelector('.bottom_container')?.classList?.add('removeDiv');
        document.querySelector('.links-container')?.classList?.add('removeDiv');
        filterState({
            status: true,
            component: <SelectProduct data={{ ...props.data }} />
        });
    }

    return (
        <div className={styles.selector_wrap}>
            {filterIsOpen === true ? null : <div className='component-spacing'>
                <Label label={t(setting.label)} tooltipText={'Product'} />
                <div className={`${PLATFORM === PlatformType.Wix ? 'wix' : 'shopify'} card`} >
                    <div>
                        {
                            isEmpty(productData) ?
                                <div className={styles.button_container} >
                                    <div>
                                        <Button className={styles.button_wrap} color='teal' variant='outline' fullWidth onClick={changeFont}>{t(setting.label)}</Button>
                                    </div>
                                </div>
                                :
                                <ProductSelectedData data={productData} setting={setting} target={target} widgetId={widgetId} parentWidgetId={parentWidgetId} index={index} />
                        }
                    </div>
                </div>
                {requiredData.showErrorMessage && isEmpty(productData) && setting.isRequired &&
                    <div className='product-selector-banner'>
                      <Banner status={BannerType.Error}>{requiredData.toastMessage}</Banner>
                    </div>
                }
            </div>}
        </div>
    )
}

export default ProductSelector

