import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import '../../Style/index.css';
import { PageData } from '../../ContextAPI/Context';
import Header from './Header';
import LeftSideBar from './LeftSideBar';
import RightSideBar from './RightSideBar';
import HelpModal from './HelpModal/index';
import {
  ButtonEvent,
  eventsName,
  PlatformType,
  Toast_Message_Type,
} from '../../Constant';
import ProgressBar, {
  ProgressColor,
  ProgressSize,
} from '../../Shared/Components/ProgressBar';
import { LAUNCH_DARKLY_A_B_TESTING_FOR_SLOTS, PLATFORM } from '../../config';
import { useTranslation } from 'react-i18next';
import OnBoardingModal from './OnBoardingModal';
import WidgetLimitModel from './WidgetLimitModel';
import {
  isOpenedFromFlowBuilder,
  isOpenedFromFlowBuilderForSuperAdmin,
} from '../../Utils/Common';
import EmptyPreview from '../EmptyPreview/EmptyPreview';
import CheckoutHeader from './CheckoutHeader';
import { Modal, Spinner } from '../../Shared/Components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { t } from 'i18next';

const Layout = ({ children }) => {
  const {
    showProgressBar,
    bannerStatus,
    unifiedEditor,
    setRightBarLinks,
    isRightBarEmpty,
    setIsRightBarEmpty,
    setBannerStatus,
    setSelectedLink,
    isRequiredDataFilled,
    configSetting,
    systemSetting,
    previewSelectedSections,
    isMobileView,
    previewType,
    setSelectedWidgetType,
    isPositionChangeOfWidget,
    isShowWidgetLimitModal,
    isLoading,
    setRightBarConfigType,
    submitData,
    latestConfigSetting,
    setToastMessage,
    setConfigSetting,
    sendPostMessage,
    triedToSelectVariant,
    setSelectedVariant,
    setTriedToSelectVariant,
    setupVariantById,
    isUnsavedChanges,
    setIsUnsavedChanges,
  } = useContext(PageData);
  const [rightBarConfig, setRightBarConfig] = useState({});
  const [removedId, setRemoveId] = useState();
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const flags = useFlags();
  const isAbTestEnabled = flags[LAUNCH_DARKLY_A_B_TESTING_FOR_SLOTS];
  const isAdmin = isOpenedFromFlowBuilderForSuperAdmin();

  const activator = useRef(null);

  const handleClick = (e, data) => {
    if (isRequiredDataFilled()) {
      if (!e) {
        handleBack(ButtonEvent.Back);
      } else {
        const width = window.innerWidth;
        if (width <= 1614 && rightBarConfig?.widgetId !== data.widgetId) {
          setLoading(true);
        }
        setSelectedWidgetType(data.componentType);
        setRightBarConfig({
          componentType: data.componentType,
          count: data.count,
          minCount: data.minCount,
          blockType: data.blockType,
          id: data.id,
          widgetId: data.widgetId,
          parentWidgetId: data.parentWidgetId,
          target: data.target,
          parentWidgetType: data.parentWidgetType,
        });
        setRightBarConfigType({
          componentType: data.componentType,
          widgetId: data.widgetId,
          parentWidgetId: data.parentWidgetId,
        });
      }
    }
  };

  const handleBack = useCallback(
    (e) => {
      if (e === ButtonEvent.Back) {
        setRightBarConfig((prevState) => ({ ...prevState, componentType: '' }));
        setRightBarLinks([]);
      }
    },
    [setRightBarLinks, setRightBarConfig]
  );

  useEffect(() => {
    if (isRightBarEmpty) {
      setRightBarConfig((prevState) => ({ ...prevState, componentType: '' }));
      setIsRightBarEmpty(false);
    }
  }, [isRightBarEmpty, setIsRightBarEmpty]);

  useEffect(() => {
    window.addEventListener('hidePreviewBanner', (e) => {
      setRemoveId(e.detail.id);
    });

    window.addEventListener('customeEvent', (e) => {
      setSelectedLink(e.detail);
    });

    return () => {
      window.removeEventListener('hidePreviewBanner', null);
      window.removeEventListener('customeEvent', null);
    };
  }, [setSelectedLink]);

  useEffect(() => {
    if (removedId) {
      let bannerSetting = bannerStatus;
      delete bannerSetting[removedId];
      setBannerStatus(bannerSetting);
      setRemoveId();
    }
  }, [removedId, setBannerStatus, bannerStatus]);

  const showHeader = useMemo(() => {
    return (
      PLATFORM === PlatformType.Wix ||
      !isOpenedFromFlowBuilder() ||
      isOpenedFromFlowBuilderForSuperAdmin()
    );
  }, [PLATFORM, isOpenedFromFlowBuilder, isOpenedFromFlowBuilderForSuperAdmin]);

  const handleSubmit = useCallback(async () => {
    if (isRequiredDataFilled()) {
      const isDataSaved = await submitData(latestConfigSetting);

      if (isDataSaved) {
        setToastMessage({
          message: 'Changes saved',
          messageType: Toast_Message_Type.Success,
        });
        unSaveWarningModalClick('save');
      } else {
        setToastMessage({
          message: 'Server error',
          messageType: Toast_Message_Type.Error,
        });
      }
    }
  }, [
    latestConfigSetting,
    isRequiredDataFilled,
    setToastMessage,
    submitData,
    triedToSelectVariant,
  ]);

  const unSaveWarningModalClick = (buttonType) => {
    if (buttonType === 'discard') {
      setConfigSetting([configSetting[0]]);
    }
    setSelectedVariant(triedToSelectVariant.variantId);
    setIsUnsavedChanges(false);
    setupVariantById({
      variantId: triedToSelectVariant.variantId,
      isNewVariant: false,
    });
    sendPostMessage(eventsName.UpdateBreadcrumb, false, {
      variantName: triedToSelectVariant.variantName,
    });
    setTriedToSelectVariant(null);
    if (PlatformType.Shopify === PLATFORM) {
      sendPostMessage('hide-save-bar');
    }
  };

  return (
    <div
      style={{ maxHeight: '100vh', overflow: 'hidden' }}
      className={showHeader ? 'wix-layout' : 'shopify-layout'}
    >
      {showProgressBar && (
        <ProgressBar size={ProgressSize.Small} color={ProgressColor.primary} />
      )}
      {/* Header section of unified */}
      {showHeader && <Header data={unifiedEditor} />}
      <div className="navigationWrapper">
        <section className="main-layout">
          <div className="main-layout-inner">
            {/* Left section of unified */}
            <div className="left-action-menu"></div>
            <div
              className={
                !rightBarConfig.componentType?.length
                  ? 'main-layout-left'
                  : 'main-layout-left sidebar-hide'
              }
            >
              <LeftSideBar
                onClick={handleClick}
                renderComponent={rightBarConfig}
              />
            </div>
            <div
              className={
                isAbTestEnabled
                  ? `main-layout-center`
                  : `main-layout-center ce-preview`
              }
            >
              {isAbTestEnabled && <CheckoutHeader />}
              <main
                className={
                  isAbTestEnabled && (PLATFORM === PlatformType.Wix || isAdmin)
                    ? 'center-section-ab-testing ce-preview'
                    : 'center-section'
                }
                style={isLoading ? { overflow: 'hidden' } : {}}
              >
                {children}
                {isLoading && (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spinner size="large" />
                  </div>
                )}
                {(configSetting[configSetting.length - 1]?.widgets?.length ||
                  0) === 0 &&
                  !isLoading &&
                  PLATFORM === PlatformType.Shopify && <EmptyPreview />}
                <div
                  style={
                    configSetting[configSetting.length - 1]?.widgets?.length ===
                      0 || 0
                      ? { display: 'none' }
                      : {}
                  }
                >
                  {PLATFORM === PlatformType.Shopify && (
                    <checkout-upsell-preview
                      value={JSON.stringify({
                        checkoutSchema: configSetting[configSetting.length - 1],
                        bannerStatus: bannerStatus,
                        selectedWidget: previewSelectedSections,
                        view: isMobileView ? 'Mobile' : 'Desktop',
                        currency: systemSetting?.currency,
                        currencySymbol: systemSetting?.currencySymbol,
                        platform: PLATFORM,
                        language: i18n.language,
                        previewType: previewType,
                        isPositionChangeOfWidget: isPositionChangeOfWidget,
                      })}
                    ></checkout-upsell-preview>
                  )}
                  {PLATFORM === PlatformType.Wix && (
                    <wix-preview
                      value={JSON.stringify({
                        checkoutSchema: configSetting[configSetting.length - 1],
                        bannerStatus: bannerStatus,
                        selectedWidget: previewSelectedSections,
                        view: isMobileView ? 'Mobile' : 'Desktop',
                        currency: systemSetting?.currency,
                        currencySymbol: systemSetting?.currencySymbol,
                        platform: PLATFORM,
                        language: i18n.language,
                        previewType: previewType,
                      })}
                    ></wix-preview>
                  )}
                </div>
              </main>
            </div>
            {/* Right section of unified */}
            <div
              className={
                rightBarConfig.componentType?.length
                  ? 'main-layout-left'
                  : 'main-layout-left sidebar-hide'
              }
            >
              <RightSideBar
                renderComponent={rightBarConfig}
                backButton={handleBack}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>
        </section>
      </div>
      <HelpModal />
      {isShowWidgetLimitModal && <WidgetLimitModel />}
      {PLATFORM === PlatformType.Wix && <OnBoardingModal />}
      {isUnsavedChanges && (
        <Modal
          rootStyles={{ height: '500px' }}
          active={isUnsavedChanges}
          onSecondaryAction={() => {
            unSaveWarningModalClick('discard');
          }}
          onClose={() => {
            setIsUnsavedChanges(false);
          }}
          onPrimaryAction={handleSubmit}
          title={t('save_changes_warning_title')}
          activator={activator}
          primaryButtonLabel={t('save')}
          secondaryButtonLabel={t('discard')}
          content={t('save_changes_warning')}
        />
      )}
    </div>
  );
};

export default Layout;
